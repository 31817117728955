<template>
  <div class="tabs-container">
    <div
      v-for="(e, i) in options"
      :key="i"
      :class="[
        'tab-container',
        {
          action: value == e[replaceFields.type],
        },
      ]"
      @click="$emit('set:value', e[replaceFields.type])"
    >
      <span>{{ e[replaceFields.lable] }}</span>
      <span
        :style="`color:${
          e[replaceFields.type] == '01' ? '#FFCC1B' : '#FE1329'
        }`"
        >（{{ e[replaceFields.value] }}）</span
      >
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [String, Number],
      default: '1',
    },
    options: {
      type: Array,
      default: () => [
        {
          type: 1,
          lable: '客户',
          value: 20,
        },
        {
          type: 2,
          lable: '柜员',
          value: 35,
        },
      ],
    },
    replaceFields: {
      type: Object,
      default: () => ({
        type: 'type',
        lable: 'lable',
        value: 'value',
      }),
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.tabs-container {
  display: flex;
  background: #01142a;
  border: 1px solid #2cadff;
  border-radius: 5px;
  overflow: hidden;
  .tab-container {
    flex: 1;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
  }
  .action {
    background: #064268;
  }
}
</style>
